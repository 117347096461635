/* eslint-disable @next/next/no-img-element */
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';
import { ReactNode, useRef } from 'react';
import useIsInViewport from 'shared/hooks/useIsInViewport';
import AnimatedNumber, { AnimatedNumberProps } from '../components/AnimatedNumber';

export interface StatisticsCircleProps extends AnimatedNumberProps {
  children?: ReactNode;
}

const Statistic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
`;

const Label = styled.div`
  @media (max-width: ${({ theme }) => theme.BREAKPOINT.MD}) {
    margin: 0 auto;
    max-width: 80%;
  }

  color: #212b36;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3rem;
  text-align: center;
`;

const CircleWithAnimatedObject = styled.div`
  position: relative;
`;

const Circle = styled.div`
  align-items: center;
  background-color: #fc8000;
  border-radius: 50%;
  color: white;
  display: flex;
  height: 20rem;
  font-size: 6rem;
  font-weight: 700;
  justify-content: center;
  line-height: 9.6rem;
  position: inherit;
  width: 20rem;
  z-index: 2;
`;

const AnimatedDots = styled(animated.div)`
  height: 80%;
  left: -5%;
  position: absolute;
  top: 0;

  img {
    height: 100%;
    width: auto;
  }
`;

const AnimatedBottomObject = styled(animated.div)`
  border: 1px solid #fc8103;
  border-top-left-radius: 100%;
  bottom: 0;
  height: 3rem;
  position: absolute;
  right: 0;
  width: 3rem;
`;

function StatisticsCircle({ numberValue, children, suffix }: StatisticsCircleProps): JSX.Element {
  const StatisticRef = useRef(null);
  const isInvitationStatisticsInView = useIsInViewport(StatisticRef, {
    threshold: 0,
  });

  function getRandomFloat(min: number, max: number, decimals: number) {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    return parseFloat(str);
  }

  const emptyProps = useSpring({});

  const dotsProps = useSpring({
    reset: true,
    loop: { reverse: true },
    from: { opacity: 0.7, scale: 1 },
    to: { opacity: 0.4, scale: getRandomFloat(1.1, 1.2, 1) },
    config: {
      mass: 1,
      friction: 15,
      tension: 15,
      duration: getRandomFloat(4000, 6000, 0),
    },
  });

  const bottomObjectProps = useSpring({
    reset: true,
    loop: { reverse: true },
    from: { opacity: 0.7, scale: 1, rotate: 0 },
    to: {
      opacity: 0.4,
      scale: getRandomFloat(1.05, 1.1, 1),
      rotate: getRandomFloat(-120, 120, 0),
    },
    config: {
      mass: 1,
      friction: 15,
      tension: 15,
      duration: getRandomFloat(4000, 6000, 0),
    },
  });

  return (
    <Statistic ref={StatisticRef}>
      <CircleWithAnimatedObject>
        <Circle>
          <AnimatedNumber numberValue={numberValue} suffix={suffix} />
        </Circle>
        <AnimatedDots style={isInvitationStatisticsInView ? dotsProps : emptyProps}>
          <img src="/img/home/v2/InvitationStatistics/dots.svg" alt="" />
        </AnimatedDots>
        <AnimatedBottomObject style={isInvitationStatisticsInView ? bottomObjectProps : emptyProps} />
      </CircleWithAnimatedObject>
      {children ? <Label>{children}</Label> : null}
    </Statistic>
  );
}

export default StatisticsCircle;
