import styled from 'styled-components';
import Trans from 'next-translate/Trans';
import Section from 'shared/components/Section';
import { BREAKPOINT } from 'config/theme';
import InvitationStatisticsHeader from './Header';
import StatisticsCircle from './StatisticCircle';

const SectionForInvitationsStatistics = styled(Section)`
  margin: 6.4rem auto;
  max-width: 148rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8rem;
  justify-content: center;
  margin-top: 6rem;

  ${({ theme }) => theme.MEDIA.LG} {
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 35rem));
    margin-top: 8rem;
  }

  @media only screen and (max-width: 1200px) and (min-width: ${BREAKPOINT.MD}) {
    column-gap: 3rem;
    grid-column-gap: 8fr;
    grid-template-columns: repeat(2, minmax(30rem, 35rem));
    row-gap: 8rem;
    margin-top: 8rem;

    & > *:nth-child(3) {
      grid-column: 1/-1;
    }
  }
`;

function InvitationStatistics(): JSX.Element {
  return (
    <SectionForInvitationsStatistics>
      <InvitationStatisticsHeader />
      <Grid>
        <StatisticsCircle numberValue={43} suffix="%">
          <Trans
            i18nKey="homePage:invitationStatisticsSection.openInvStats"
            values={{
              openInvStats: 43,
            }}
            components={[<b key="b" />]}
            fallback="homePageEN:invitationStatisticsSection.openInvStats"
          />
        </StatisticsCircle>
        <StatisticsCircle numberValue={30}>
          <Trans
            i18nKey="homePage:invitationStatisticsSection.reviewStats"
            values={{
              reviewStats: 30,
            }}
            components={[<b key="b" />]}
            fallback="homePageEN:invitationStatisticsSection.reviewStats"
          />
        </StatisticsCircle>
        <StatisticsCircle numberValue={230}>
          <Trans
            i18nKey="homePage:invitationStatisticsSection.averageLengthStats"
            values={{
              averageLengthStats: 230,
            }}
            components={[<b key="b" />]}
            fallback="homePageEN:invitationStatisticsSection.averageLengthStats"
          />
        </StatisticsCircle>
      </Grid>
    </SectionForInvitationsStatistics>
  );
}

export default InvitationStatistics;
