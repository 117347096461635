import { useCountUp } from 'react-countup';

export interface AnimatedNumberProps {
  numberValue: number;
  prefix?: string;
  suffix?: string;
  alternativeRef?: string;
}

function AnimatedNumber({
  numberValue,
  prefix = '',
  suffix = '',
  alternativeRef = '',
}: AnimatedNumberProps): JSX.Element {
  useCountUp({
    ref: alternativeRef || numberValue.toString(),
    end: numberValue,
    duration: 3.2,
    enableScrollSpy: true,
    scrollSpyDelay: 300,
    scrollSpyOnce: true,
    prefix,
    suffix,
  });

  return (
    <>
      <span id={alternativeRef || numberValue.toString()} />
    </>
  );
}

export default AnimatedNumber;
