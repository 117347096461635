import styled from 'styled-components';
import useTranslation from 'shared/hooks/useTranslation';
import { Heading, SubHeading } from '../Styled';

const HeadingWithWidth = styled(Heading)`
  margin: 0 auto;

  ${({ theme }) => theme.MEDIA.MD} {
    font-size: 4rem;
    line-height: 6rem;
    max-width: 80%;
  }
  ${({ theme }) => theme.MEDIA.XL} {
    font-size: 6.4rem;
    line-height: 7.8rem;
  }
`;

function InvitationStatisticsHeader(): JSX.Element {
  const { t } = useTranslation('homePage');

  return (
    <>
      <HeadingWithWidth>{t('invitationStatisticsSection.noTime')}</HeadingWithWidth>
      <SubHeading>{t('invitationStatisticsSection.bottleNeck')}</SubHeading>
    </>
  );
}

export default InvitationStatisticsHeader;
